import React, { FC, useCallback, useState } from 'react';

import { navigate } from 'gatsby';
import { withNotifications } from 'src/components/notification-container/notification-container';
import { useFormattedTerms } from 'src/components/saas-memberships/hooks';
import Spinner from 'src/components/spinner/spinner';
import { Membership_membership_terms } from 'src/graphql-types/saas/Membership';
import { StringParam, useQueryParams } from 'use-query-params';

import { useGetMainContactDetails } from './data-hooks/useGetMainContactDetails';
import { useGetMember } from './data-hooks/useGetMember';
import { useGetMembership } from './data-hooks/useGetMembership';
import { useGetOrganisationPurchasedTerms } from './data-hooks/useGetOrganisationPurchasedTerms';
import MemberDetails from './member-details';

interface MemberDetailsContainerProps {
  membershipId: string;
  organisationId: string;
}
const MemberDetailsContainer: FC<MemberDetailsContainerProps> = ({ membershipId, organisationId }) => {
  const [selectedTerm, setSelectedTerm] = useState<Membership_membership_terms>();
  const [urlParams] = useQueryParams({ termId: StringParam });

  const { membership } = useGetMembership({
    membershipId,
    notFoundOnFail: true,
    onComplete: () => {
      setTerm();
    },
    // TODO: navigate to proper error page instead of 404
    onError: () => navigate('/404'),
  });

  const { organisation } = useGetMainContactDetails({
    organisationId,
    notFoundOnFail: true,
    onError: () => navigate('/404'),
  });

  const terms = membership?.membership?.terms;
  const { terms: purchasedTerms } = useGetOrganisationPurchasedTerms({
    organisationId,
    membershipId,
  });

  //terms
  const availableTermsForMember = useFormattedTerms(
    terms ?? [],
    purchasedTerms ? purchasedTerms.map((term) => term.termId) : [],
  );

  const setTerm = useCallback(() => {
    setSelectedTerm(terms?.find((term) => term.termId === urlParams.termId));
  }, [terms, urlParams.termId]);

  const onTermSelected = (value) => {
    setSelectedTerm(terms?.find((term) => term.termId === value));
  };

  const { member, loading: loadingMember } = useGetMember({
    organisationId,
    membershipId,
    termId: selectedTerm?.termId ?? '',
    notFoundOnFail: true,
    onError: () => navigate('/404'),
  });

  if (!member?.getMember || !membership?.membership) {
    return <Spinner />;
  } else {
    return (
      <MemberDetails
        loadingMember={loadingMember}
        loadingTransactions={loadingMember}
        member={member?.getMember}
        organisation={organisation?.getOrganisationById}
        membership={membership?.membership}
        terms={availableTermsForMember}
        selectedTerm={selectedTerm}
        transactions={member?.getMember?.payment ? [member?.getMember?.payment] : []}
        onTermChange={onTermSelected}
      ></MemberDetails>
    );
  }
};
export default withNotifications(MemberDetailsContainer);
