import React from 'react';

import { Divider } from '@mui/material';
import Grid from '@mui/material/Grid';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import Dropdown, { Option } from 'src/components/dropdown/dropdown';
import Panel from 'src/components/panel/panel';
import * as styles from 'src/components/saas-memberships/saas-memberships.module.less';
import StatusLabel from 'src/components/status-label/status-label';
import { BodySmall, BodySmallBold, H2 } from 'src/components/typography/typography';
import { MemberStatus } from 'src/graphql-types/globalSaasTypes';
import { GetMainContactDetails_getOrganisationById } from 'src/graphql-types/saas/GetMainContactDetails';
import { GetMember_getMember } from 'src/graphql-types/saas/GetMember';
import { Membership_membership_terms } from 'src/graphql-types/saas/Membership';

interface MemberHeaderProps {
  member?: GetMember_getMember | null;
  organisation?: GetMainContactDetails_getOrganisationById | null;
  selectedTerm?: Membership_membership_terms | null;
  loading: boolean;
  termOptions: Option[];
  setSelectedTerm: (value: string) => void;
}
export const MemberHeader: React.FC<MemberHeaderProps> = ({
  member,
  selectedTerm,
  termOptions,
  setSelectedTerm,
  organisation,
}) => {
  const { t } = useTranslation();
  const { standardGivenName, standardFamilyName } = organisation?.mainContact || {};
  const mainContactName = standardGivenName && standardFamilyName ? `${standardGivenName} ${standardFamilyName}` : '-';
  return (
    <Panel>
      <Grid container justifyContent="space-between" alignItems="center" alignContent="center">
        <Grid container>
          <Grid container item alignItems="center">
            <H2>{member?.organisationName}</H2>
            <Dropdown
              testId="member-header-dropdown"
              options={termOptions}
              disabled={!termOptions.length}
              value={selectedTerm?.termId ?? ''}
              selected={selectedTerm?.termId ?? ''}
              onSelect={(o) => setSelectedTerm(o.value)}
              flat
              spacing={{ margins: { xxs: 'top', xs: 'left' } }}
              selectClassname={styles.dropdown}
            />
          </Grid>
          <Grid item xs={12} sm={12}></Grid>
          <Divider className={styles.divider} />
          <Grid container direction="row" alignItems="center">
            <Grid item xs={6} sm={6} md={6} lg={2}>
              <BodySmallBold>{t('start date')}</BodySmallBold>
              <BodySmall>{t('member-start-date', { date: dayjs.utc(selectedTerm?.startDate) })}</BodySmall>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={2}>
              <BodySmallBold>{t('end date')}</BodySmallBold>
              <BodySmall>{t('member-end-date', { date: dayjs.utc(selectedTerm?.endDate) })}</BodySmall>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={2}>
              <BodySmallBold>{t('member status')}</BodySmallBold>
              {member?.memberStatus && (
                <StatusLabel variety={member.memberStatus === MemberStatus.ACTIVE ? 'success' : 'warning'}>
                  {t(`memberStatusOptions.${member.memberStatus}`)}
                </StatusLabel>
              )}
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={2}>
              <BodySmallBold>{t('full name')}</BodySmallBold>
              <BodySmall>{mainContactName}</BodySmall>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={2}>
              <BodySmallBold>{t('email address')}</BodySmallBold>
              <BodySmall>{organisation?.mainContact?.email ?? '-'}</BodySmall>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={2}>
              <BodySmallBold>{t('phone number')}</BodySmallBold>
              <BodySmall>{organisation?.mainContact?.phoneNumber ?? '-'}</BodySmall>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Panel>
  );
};
