import React from 'react';

import { RouteComponentProps, Router } from '@reach/router';
import { navigate } from 'gatsby';
import Layout from 'src/components/layout/layout';
import { PrivateRoute } from 'src/components/private-route/private-route';
import MemberDetails from 'src/components/saas-memberships/member-details';
import SEO from 'src/components/seo';

const MemberDetailsPage = () => {
  return (
    <Router basepath="/saas-memberships">
      <MemberDetailsRoute path="/:membershipId/organisation/:organisationId" />
    </Router>
  );
};

interface MemberDetailsRouteProps extends RouteComponentProps {
  membershipId?: string;
  organisationId?: string;
}

const MemberDetailsRoute: React.FC<MemberDetailsRouteProps> = ({ membershipId, organisationId }) => {
  if (!membershipId || !organisationId) {
    navigate('/404');
    return null;
  } else {
    return (
      <Layout>
        <SEO title="Member Details" />
        <PrivateRoute>
          <MemberDetails membershipId={membershipId} organisationId={organisationId} />
        </PrivateRoute>
      </Layout>
    );
  }
};

export default MemberDetailsPage;
