import React, { FC } from 'react';

import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import AdminTable, { DataCols } from 'src/components/admin-table/admin-table';
import Breadcrumbs from 'src/components/breadcrumbs/breadcrumbs';
import { Option } from 'src/components/dropdown/dropdown';
import Panel from 'src/components/panel/panel';
import * as styles from 'src/components/saas-memberships/saas-memberships.module.less';
import StatusLabel from 'src/components/status-label/status-label';
import { PageMaxWidth } from 'src/components/util-components/util-components';
import { PaymentStatus } from 'src/graphql-types/globalSaasTypes';
import { GetMainContactDetails_getOrganisationById } from 'src/graphql-types/saas/GetMainContactDetails';
import { GetMember_getMember, GetMember_getMember_payment as MemberPayment } from 'src/graphql-types/saas/GetMember';
import { Membership_membership, Membership_membership_terms } from 'src/graphql-types/saas/Membership';
import ROUTES from 'src/utils/routes';

import CancelInvoice from './invoice/cancel-invoice';
import PayInvoice from './invoice/pay-invoice';
import { MemberHeader } from './member-header';

interface MemberDetailsProps {
  membership?: Membership_membership | null;
  terms?: Option[] | null;
  selectedTerm?: Membership_membership_terms | null;
  member?: GetMember_getMember | null;
  organisation?: GetMainContactDetails_getOrganisationById | null;
  transactions?: (MemberPayment | null)[] | null;
  loadingMember: boolean;
  loadingTransactions: boolean;
  onTermChange: (termId: string) => void;
}
const MemberDetails: FC<MemberDetailsProps> = ({
  member,
  membership,
  terms,
  selectedTerm,
  transactions,
  loadingMember,
  loadingTransactions,
  onTermChange,
  organisation,
}) => {
  const { t } = useTranslation();
  const statusLabel = (status: string | null) => {
    if (!status) return '';
    const statusOptions = {
      Paid: { variety: 'success', label: t('paid') },
      Pending: { variety: 'warning', label: t('pending') },
      Cancelled: { variety: 'error', label: t('cancelled') },
    };
    return (
      <StatusLabel variety={statusOptions[status]?.variety ?? 'neutral'}>
        {statusOptions[status]?.label ?? status}
      </StatusLabel>
    );
  };

  const columns: DataCols<MemberPayment & { id: string }> = [
    {
      key: 'email',
      getValue: (item) => item.userEmail,
      title: t('email'),
    },
    {
      key: 'date',
      getValue: (item) => {
        const date = dayjs(item.date).local();
        return item.date ? t('pay date format', { date }) : '-';
      },
      title: t('date'),
    },
    {
      key: 'paymentMethod',
      getValue: (item) => item.method,
      title: t('pay method'),
    },
    {
      key: 'referenceNumber',
      getValue: (item) => item.referenceNumber,
      title: t('reference number'),
    },
    {
      key: 'status',
      getValue: (item) => statusLabel(item.status),
      title: t('status'),
    },
    {
      key: 'total',
      getValue: (item) => {
        if (!item.total) {
          return '-';
        }
        return new Intl.NumberFormat('en-CA', { style: 'currency', currency: 'CAD' }).format(item.total / 100);
      },
      title: t('total'),
    },
    {
      key: 'actions',
      getValue: (item) =>
        item?.status === PaymentStatus.PENDING && (
          <div className={styles.actionsButtonsStyle}>
            <CancelInvoice />
            <PayInvoice memberId={member?.memberId} />
          </div>
        ),
      title: t('actions'),
    },
  ];
  return (
    <PageMaxWidth>
      <Breadcrumbs
        paths={[
          { name: t('membership list'), to: `${ROUTES.SAAS_MEMBERSHIPS}`, highlight: true },
          {
            name: membership?.name ?? '',
            to: `${ROUTES.SAAS_MEMBERSHIPS}/${membership?.membershipId}`,
            highlight: true,
          },
          { name: member?.organisationName ?? 'not set', active: true },
        ]}
      />
      <MemberHeader
        member={member}
        loading={loadingMember}
        organisation={organisation}
        setSelectedTerm={onTermChange}
        selectedTerm={selectedTerm}
        termOptions={terms ?? []}
      />
      <Panel extendedPadding title={t('latest transactions')}>
        <AdminTable
          hideTopPaginationInfo
          //all of this is just to be able to keep the table
          //as we will eventually display multiple transactions
          //but for the dayjs we only have one
          data={
            (transactions?.map((t, i) => ({ ...t, id: i.toString() })) ?? []) as (MemberPayment & {
              id: string;
            })[]
          }
          loading={loadingTransactions}
          columns={columns}
        />
      </Panel>
    </PageMaxWidth>
  );
};

export default MemberDetails;
