import { useQuery } from '@apollo/client';
import { navigate } from 'gatsby';
import { meshGatewayClient } from 'src/apollo/client';
import { GET_MEMBER } from 'src/components/saas-memberships/saas-memberships-queries';
import { GetMember, GetMemberVariables } from 'src/graphql-types/saas/GetMember';

export const useGetMember = (input: {
  organisationId: string;
  membershipId: string;
  termId: string;
  notFoundOnFail?: boolean;
  onComplete?: (member) => void;
  onError?: (err) => void;
}) => {
  const {
    data: member,
    loading,
    refetch,
  } = useQuery<GetMember, GetMemberVariables>(GET_MEMBER, {
    client: meshGatewayClient,
    variables: {
      organisationId: input.organisationId,
      membershipId: input.membershipId,
      termId: input.termId,
    },
    skip: !input.termId,
    onCompleted: (member) => {
      if (!member.getMember && input.notFoundOnFail) navigate('/404');
      if (input.onComplete) input.onComplete(member.getMember);
    },
    onError: (err) => {
      if (input.onError) input.onError(err);
    },
  });
  return {
    member,
    loading,
    refetch,
  };
};
