import { useQuery } from '@apollo/client';
import { navigate } from 'gatsby';
import { meshGatewayClient } from 'src/apollo/client';
import { GET_MAIN_CONTACT_DETAILS } from 'src/components/saas-memberships/saas-memberships-queries';
import { GetMainContactDetails, GetMainContactDetailsVariables } from 'src/graphql-types/saas/GetMainContactDetails';

export const useGetMainContactDetails = (input: {
  organisationId: string;
  notFoundOnFail?: boolean;
  onComplete?: (membership) => void;
  onError?: (err) => void;
}) => {
  //membership
  const { data: organisation, loading } = useQuery<GetMainContactDetails, GetMainContactDetailsVariables>(
    GET_MAIN_CONTACT_DETAILS,
    {
      client: meshGatewayClient,
      variables: {
        organisationId: input.organisationId,
      },
      onCompleted: ({ getOrganisationById }) => {
        if (!getOrganisationById && input.notFoundOnFail) navigate('/404');
        if (input.onComplete) input.onComplete(organisation);
      },
      onError: (err) => {
        if (input.onError) input.onError(err);
      },
    },
  );
  return {
    organisation,
    loading,
  };
};
