import { useQuery } from '@apollo/client';
import { navigate } from 'gatsby';
import { meshGatewayClient } from 'src/apollo/client';
import { GET_MEMBERSHIP } from 'src/components/saas-memberships/saas-memberships-queries';
import { Membership, MembershipVariables } from 'src/graphql-types/saas/Membership';

export const useGetMembership = (input: {
  membershipId: string;
  notFoundOnFail?: boolean;
  onComplete?: (membership) => void;
  onError?: (err) => void;
}) => {
  //membership
  const { data: membership, loading } = useQuery<Membership, MembershipVariables>(GET_MEMBERSHIP, {
    client: meshGatewayClient,
    variables: {
      membershipId: input.membershipId,
    },
    onCompleted: ({ membership }) => {
      if (!membership && input.notFoundOnFail) navigate('/404');
      if (input.onComplete) input.onComplete(membership);
    },
    onError: (err) => {
      if (input.onError) input.onError(err);
    },
  });
  return {
    membership,
    loading,
  };
};
