import React, { useState } from 'react';

import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import DatePicker from 'src/components/date-picker/date-picker';
import ButtonModal from 'src/components/modal/modal';
import { withNotifications } from 'src/components/notification-container/notification-container';

import { useMarkInvoiceAsPaidForMember } from './data-hooks/useMarkInvoiceAsPaidForMember';

interface PayInvoiceContentProps {
  date: Date;
  onDateSelected: (date: Date) => void;
}
const PayInvoiceContent: React.FC<PayInvoiceContentProps> = ({ date, onDateSelected }) => {
  const { t } = useTranslation();
  const filterFutureTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() > selectedDate.getTime();
  };
  return (
    <div>
      <p>{t('membership-invoice.select-payment-date')}</p>
      <DatePicker
        name="date"
        onChange={onDateSelected}
        selected={date}
        showTimeSelect
        timeIntervals={10}
        filterTime={filterFutureTime}
        maxDate={new Date()}
        customDateFormat="dd MMM, yyyy, hh:mm"
      />
    </div>
  );
};
interface IFormInput {
  date: Date;
}
export interface PayInvoiceProps {
  memberId?: string | null;
  onSuccess?: ((date) => void | Promise<void>) | null;
}

const PayInvoice: React.FC<PayInvoiceProps> = ({ memberId, onSuccess }) => {
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation();
  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {
      date: new Date(Date.now()),
    },
  });

  const [markInvoiceAsPaidForMember, { loading: payInvoiceLoader, error: markInvoiceAsPaidError }] =
    useMarkInvoiceAsPaidForMember();

  const payInvoice = async (date: Date) => {
    try {
      if (markInvoiceAsPaidError) {
        throw markInvoiceAsPaidError;
      }
      if (!memberId) {
        throw new Error(t('membership-invoice.memberId-not-provided-error'));
      }
      await markInvoiceAsPaidForMember({
        variables: {
          memberId: memberId,
          paymentDate: date,
        },
      });
    } catch (e) {
      toast.error(t('membership-invoice.mark-invoice-paid-error', { error: e }));
    }
  };

  const onSubmit: SubmitHandler<IFormInput> = async ({ date }) => {
    await payInvoice(date);
    if (onSuccess) await onSuccess(date);
    setVisible(false);
  };

  return (
    <ButtonModal
      hideCloseButton={true}
      openButton={{
        content: t('pay'),
        props: {
          type: 'button',
          level: 'primary',
          onClick: () => setVisible(true),
        },
      }}
      cancelButton={{
        props: { type: 'button', level: 'secondary', onClick: () => setVisible(false) },
      }}
      actionButtons={[
        {
          id: 'confirm',
          content: t('confirm'),
          disableAutoClose: true,
          props: {
            type: 'submit',
            level: 'primary',
            loading: payInvoiceLoader,
            onClick: async () => {
              await handleSubmit(onSubmit)();
            },
          },
        },
      ]}
      title={t('Record payment')}
      show={visible}
    >
      <form onSubmit={(e) => e.preventDefault()}>
        <Controller
          name="date"
          control={control}
          render={({ field }) => (
            <PayInvoiceContent
              date={field.value}
              onDateSelected={(date) => {
                setValue('date', date);
              }}
            />
          )}
        />
      </form>
    </ButtonModal>
  );
};
export default withNotifications(PayInvoice);
