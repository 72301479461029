import { useMutation } from '@apollo/client';
import { meshGatewayClient } from 'src/apollo/client';
import { MARK_INVOICE_AS_PAID } from 'src/components/saas-memberships/saas-memberships-queries';
import {
  MarkInvoiceAsPaidForMember,
  MarkInvoiceAsPaidForMemberVariables,
} from 'src/graphql-types/saas/MarkInvoiceAsPaidForMember';

export const useMarkInvoiceAsPaidForMember = () => {
  return useMutation<MarkInvoiceAsPaidForMember, MarkInvoiceAsPaidForMemberVariables>(MARK_INVOICE_AS_PAID, {
    client: meshGatewayClient,
  });
};
