import { useQuery } from '@apollo/client';
import { meshGatewayClient } from 'src/apollo/client';
import { GET_ORGANISATION_PURCHASED_TERMS } from 'src/components/saas-memberships/saas-memberships-queries';
import {
  GetOrganisationPurchasedTerms,
  GetOrganisationPurchasedTermsVariables,
} from 'src/graphql-types/saas/GetOrganisationPurchasedTerms';

export const useGetOrganisationPurchasedTerms = (input: {
  organisationId: string;
  membershipId: string;
  onComplete?: (member) => void;
  onError?: (err) => void;
}) => {
  const { data, loading } = useQuery<GetOrganisationPurchasedTerms, GetOrganisationPurchasedTermsVariables>(
    GET_ORGANISATION_PURCHASED_TERMS,
    {
      client: meshGatewayClient,
      variables: {
        organisationId: input.organisationId,
        membershipId: input.membershipId,
      },
    },
  );
  return {
    terms: data?.findMembers.results,
    loading,
  };
};
