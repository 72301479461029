import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import ButtonModal from 'src/components/modal/modal';
import { withNotifications } from 'src/components/notification-container/notification-container';

export interface CancelInvoiceModalProps {
  onConfirm?: (() => Promise<void> | void) | null;
}

const CancelInvoice: React.FC<CancelInvoiceModalProps> = ({ onConfirm }) => {
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation();
  return (
    <ButtonModal
      hideCloseButton={true}
      openButton={{
        content: t('cancel'),
        props: {
          type: 'button',
          level: 'warningOutlined',
          onClick: () => setVisible(true),
        },
      }}
      cancelButton={{
        props: {
          type: 'button',
          level: 'secondary',
          onClick: () => setVisible(false),
          spacing: { margins: { xxs: ['all'] } },
        },
      }}
      actionButtons={[
        {
          id: 'confirm',
          content: t('confirm'),
          disableAutoClose: true,
          props: {
            type: 'submit',
            level: 'primary',
            spacing: { margins: { xxs: ['all'] } },
            onClick: async () => {
              if (onConfirm) await onConfirm();
              setVisible(false);
            },
          },
        },
      ]}
      title={t('membership-invoice.cancel-invoice-title')}
      show={visible}
    >
      <p>{t('membership-invoice.cancel-invoice-reminder')}</p>
    </ButtonModal>
  );
};
export default withNotifications(CancelInvoice);
