import { useMemo } from 'react';

import { Option } from 'src/components/dropdown/dropdown';
import { Membership_membership_terms } from 'src/graphql-types/saas/Membership';

export const useFormattedTerms = (terms: Membership_membership_terms[], purchasedTermsIds: string[]) => {
  return useMemo(() => {
    const purchasedTerms = [...(terms ?? [])]
      .filter((t) => purchasedTermsIds?.includes(t.termId))
      .sort((a, z) => new Date(z?.startDate).getTime() - new Date(a?.startDate).getTime());
    const termOptions: Option[] = purchasedTerms.map((term) => {
      return { label: term.name, value: term.termId };
    });
    return termOptions;
  }, [terms, purchasedTermsIds]);
};
